import { ReactNode, Suspense, lazy } from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import MainLayout from '@/layouts/Main'
// import Home from '@/pages/HomePage'

const HomePage = lazy(
  () => import(/* webpackChunkName: "home-page" */ '@/pages/HomePage'),
)
const TelegramBot = lazy(
  () => import(/* webpackChunkName: "telegram-bot" */ '@/pages/TelegramBot'),
)
const AlphaScanner = lazy(
  () => import(/* webpackChunkName: "alpha-scanner" */ '@/pages/AlphaScanner'),
)
const Earn = lazy(() => import(/* webpackChunkName: "earn" */ '@/pages/Earn'))
const Dashboard = lazy(
  () => import(/* webpackChunkName: "dashboard" */ '@/pages/Dashboard'),
)
const NotFound = lazy(
  () => import(/* webpackChunkName: "not-found" */ '@/pages/NotFound/NotFound'),
)
const Routes = ({ children }: { children?: ReactNode }) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          // key={`route-route-config-${routeConfigIndex}`}
          exact
          path={['/', '/telegram-bot', '/alpha-scanner', '/earn', '/dashboard']}
        >
          <MainLayout>
            <Suspense fallback={null}>
              <Route exact path="/" component={HomePage}></Route>
              <Route exact path="/telegram-bot" component={TelegramBot}></Route>
              <Route
                exact
                path="/alpha-scanner"
                component={AlphaScanner}
              ></Route>
              <Route exact path="/earn" component={Earn}></Route>
              <Route exact path="/dashboard" component={Dashboard}></Route>
            </Suspense>
          </MainLayout>
        </Route>
        <Route path="*">
          <MainLayout>
            <Suspense fallback={null}>
              <Route path="*" component={NotFound}></Route>
            </Suspense>
          </MainLayout>
        </Route>
      </Switch>
      {children}
    </BrowserRouter>
  )
}
export default Routes

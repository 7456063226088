import sample from 'lodash/sample'
export const ETH_MAINNET = 1
export const ETH_GOERLI = 5
// TODO: need remove true after fix goerli test flow.
const isProd = () => true || window.location.hostname === 'alpharadarbot.xyz'
export const DEFAULT_CHAIN_ID = isProd() ? ETH_MAINNET : ETH_GOERLI
// TODO: need leave only mainnet when prod
export const SUPPORTED_CHAIN_IDS = isProd()
  ? [ETH_MAINNET]
  : [ETH_GOERLI, ETH_MAINNET]
export const CHAIN_NAMES_MAP = {
  [ETH_MAINNET]: 'mainnet',
  [ETH_GOERLI]: 'goerli',
}
export const RPC_PROVIDERS = {
  // [ETH_MAINNET]: ['https://rpc.ankr.com/eth'],
  [ETH_MAINNET]: [
    'https://eth-mainnet.g.alchemy.com/v2/fg70dVjK3rPb5JAUvJOw-8Ps6g5WjCMs',
  ],
  // [ETH_MAINNET]:
  //   'https://eth-mainnet.g.alchemy.com/v2/fg70dVjK3rPb5JAUvJOw-8Ps6g5WjCMs',
  // [ETH_GOERLI]: ['https://rpc.goerli.mudit.blog/'],
  // [ETH_GOERLI]:
  //   'https://eth-goerli.g.alchemy.com/v2/uAhSxTjbZUhzZX7LkHmkjCkMo-LB8yBO',
  [ETH_GOERLI]: 'https://ethereum-goerli.publicnode.com',
}
export const FALLBACK_PROVIDERS = {
  // [ETH_MAINNET]: 'https://rpc.ankr.com/eth',

  [ETH_MAINNET]: [
    'https://eth-mainnet.g.alchemy.com/v2/fg70dVjK3rPb5JAUvJOw-8Ps6g5WjCMs',
  ],
  [ETH_GOERLI]: [
    'https://eth-goerli.g.alchemy.com/v2/uAhSxTjbZUhzZX7LkHmkjCkMo-LB8yBO',
  ],
}
export function getChainName(chainId: number) {
  return CHAIN_NAMES_MAP[chainId]
}
export function getFallbackRpcUrl(chainId: number): string | undefined {
  return sample(FALLBACK_PROVIDERS[chainId])
}
export function getRpcUrl(chainId: number): string | undefined {
  return sample(RPC_PROVIDERS[chainId])
}

import { useEffect, useState } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import { SUPPORTED_CHAIN_IDS } from '@/config/chains'
import { Web3ReactManagerFunctions } from '@web3-react/core/dist/types'
const injectedConnector = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})
export function getInjectedConnector() {
  return injectedConnector
}
// TODO: all const change to string, need change back
export function clearWalletConnectData() {
  localStorage.removeItem('WALLET_CONNECT_V2_LOCALSTORAGE_KEY')
}
// TODO: check become real function
const getWalletConnectConnector = () => {}
export function activateInjectedProvider(providerName: string) {
  const { ethereum } = window

  if (
    !ethereum?.providers &&
    !ethereum?.isCoinbaseWallet &&
    !ethereum?.isMetaMask
  ) {
    console.log('all no')
    return undefined
  }

  let provider
  if (ethereum?.providers) {
    switch (providerName) {
      case 'CoinBase':
        provider = ethereum.providers.find(
          ({ isCoinbaseWallet }) => isCoinbaseWallet,
        )
        break
      case 'MetaMask':
      default:
        provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask)
        break
    }
  }

  if (provider) {
    ethereum?.setSelectedProvider?.(provider)
  }
}
export function clearWalletLinkData() {
  Object.entries(localStorage)
    .map((x) => x[0])
    .filter((x) => x.startsWith('WALLET_LINK_LOCALSTORAGE_PREFIX'))
    .map((x) => localStorage.removeItem(x))
}
export const getInjectedHandler = (
  activate: Web3ReactManagerFunctions['activate'],
  deactivate: Web3ReactManagerFunctions['deactivate'],
) => {
  const fn = async () => {
    activate(getInjectedConnector(), (e) => {
      if (e instanceof UnsupportedChainIdError) {
        // showUnsupportedNetworkToast();

        deactivate()

        return
      }

      const errString = e.message ?? e.toString()
      // helperToast.error(errString)
    })
  }
  return fn
}

// TODO: all const change to string, need change back
export function useEagerConnect(
  setActivatingConnector: (connector: any) => void,
) {
  const { activate, active } = useWeb3React()
  const [tried, setTried] = useState(false)

  useEffect(() => {
    ;(async function () {
      if (
        Boolean(
          localStorage.getItem('SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY'),
        ) !== true
      ) {
        // only works with WalletConnect
        clearWalletConnectData()
        // force clear localStorage connection for MM/CB Wallet (Brave legacy)
        clearWalletLinkData()
        return
      }

      let shouldTryWalletConnect = false
      try {
        // naive validation to not trigger Wallet Connect if data is corrupted
        const rawData = localStorage.getItem(
          'WALLET_CONNECT_V2_LOCALSTORAGE_KEY',
        )
        if (rawData) {
          const data = JSON.parse(rawData)
          if (data && data.connected) {
            shouldTryWalletConnect = true
          }
        }
      } catch (ex) {
        if (ex instanceof SyntaxError) {
          // rawData is not a valid json
          clearWalletConnectData()
        }
      }
      if (shouldTryWalletConnect) {
        try {
          const connector = getWalletConnectConnector()
          setActivatingConnector(connector)
          // TODO: this line is command for test, need change bakc
          // await activate(connector, undefined, true)
          // in case Wallet Connect is activated no need to check injected wallet
          return
        } catch (ex) {
          // assume data in localstorage is corrupted and delete it to not retry on next page load
          clearWalletConnectData()
        }
      }

      try {
        const connector = getInjectedConnector()
        const currentProviderName =
          localStorage.getItem('CURRENT_PROVIDER_LOCALSTORAGE_KEY') ?? false
        if (currentProviderName !== false) {
          activateInjectedProvider(currentProviderName)
        }
        const authorized = await connector.isAuthorized()
        if (authorized) {
          setActivatingConnector(connector)
          await activate(connector, undefined, true)
        }
      } catch (ex) {}

      setTried(true)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true)
    }
  }, [tried, active])

  return tried
}
export function useInactiveListener(suppress = false) {
  const injected = getInjectedConnector()
  const { active, error, activate } = useWeb3React()

  useEffect(() => {
    const { ethereum } = window
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        activate(injected)
      }
      const handleChainChanged = (chainId) => {
        activate(injected)
      }
      const handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
          activate(injected)
        }
      }
      const handleNetworkChanged = (networkId) => {
        activate(injected)
      }

      ethereum.on('connect', handleConnect)
      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)
      ethereum.on('networkChanged', handleNetworkChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect)
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
          ethereum.removeListener('networkChanged', handleNetworkChanged)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, error, suppress, activate])
}

// TODO: can check arbot-web
export const switchNetwork = async (chainId: number) => {
  // switch chain
  try {
    const chainIdHex = '0x' + chainId.toString(16)
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainIdHex }],
    })
  } catch (ex: any) {
    // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
    // This error code indicates that the chain has not been added to MetaMask.
    // 4001 error means user has denied the request
    // If the error code is not 4001, then we need to add the network
    if (ex.code !== 4001) {
      return await window.ethereum
        .request({
          method: 'wallet_addEthereumChain',
          params: [`0x${chainId}`],
        })
        .catch()
    }

    // eslint-disable-next-line no-console
    console.error('error', ex)
  }
}